@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.ant-menu-inline .ant-menu-item:after {
    border-right: 3px solid #39B13D !important;
}

/* .ant-menu-item-selected {
    background-color: #ffffff !important;
} */
.ant-form-item .ant-form-item-label>label {
    /* font-family: 'Nunito Sans' !important; */
    font-size: 14px !important;
    /* font-weight: bold; */
}
.upload.ant-form-item .ant-form-item-label >label {
    /* font-family: 'Nunito Sans' !important; */
    font-size: 14px !important;
    font-weight: bold;
}

.CardExo.ant-card .ant-card-actions>li {
    width: 10% !important;
}
.cardItem.ant-card .ant-card-actions>li {
    width: 18% !important;
}

.cardItem.ant-card .ant-card-actions {
    justify-content: flex-end;
}
.itemsList.ant-card .ant-card-actions {
    justify-content: space-around;
}
.CardExo.ant-card .ant-card-actions {
    justify-content: flex-end;
}

.shadowRight {
    box-shadow: 10px 3px 10px -13px #00000033;
}

.proClientSpinner .ant-spin-dot-item {
    background-color: #fff !important;
}