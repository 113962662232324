@font-face {
    font-family: 'avenir-next';
    src: url('../../../fonts/avenir-next-rounded-pro-medium.otf') format('opentype');
 }

.activationBody {
    font-family: 'avenir-next', sans-serif;
}

.activationBody p {
    font-family: 'avenir-next';
}

.activationBody h1 {
    font-family: 'avenir-next' !important;
}

.activationBody button.customButton {
    font-family: 'avenir-next' !important;
    font-size: 18px !important;
    font-weight: bold !important;
    height: 7vh !important;
    width: 21vw;
    margin-left: 2vw;
    margin-right: 2vw;
}

.signinContent {
    width: 40vw
}

@media (max-width: 768px) {
    .activationBody button.customButton {
        font-family: 'avenir-next' !important;
        font-size: 14px !important;
        font-weight: bold !important;
        height: 6vh !important;
        width: 42vw;
        margin-left: 2vw;
        margin-right: 2vw;
    }

    .signinContent {
        width: 100vw;
        align-items: center;
        justify-content: center;
    }
}

.ant-picker :hover {
    cursor: default;
}

.activationBody label {
    font-family: 'avenir-next' !important;
    font-size: 18px !important;
    color: black;
    font-weight: bold;
}

.inputField {
    font-size: 20px !important;
    border-radius: 0px !important;
    padding-left: 0px;
    width: 30vw;
    border-bottom: solid 1px #42D83F !important;
}

.inputFieldMobile {
    font-size: 24px !important;
    border-radius: 0px !important;
    padding-left: 0px;
    width: 100%;
    border: 0;
    border-bottom: solid 1px #42D83F !important;
}

.anticon-eye-invisible>svg {
    fill: #42D83F;
}

.anticon-eye>svg {
    fill: #42D83F;
}

.activationBody label.ant-checkbox-wrapper {
    font-family: 'avenir-next' !important;
    font-weight: 500;
    font-size: 18px;
    color: #000000;
}

.activationBody label.ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #42D83F !important;
    border: 2px solid #42D83F !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #42D83F;
    border-color: #42D83F;
    animation-duration: 0s !important;
}


.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #42D83F;
    border-color: #42D83F;
    animation-duration: 0s !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #42D83F !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #42D83F !important;
}

#check {
    animation-duration: 0s !important;
}

.horizontalDialog {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: justify;
    line-height: normal;

}

.horizontalDialog img {
    display: block;
    margin: 0 auto;
}

.RadioButton.ant-radio-button-wrapper {
    width: 100%;
    text-align: left;
    height: 7vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 15px !important;
    border: solid 1px #EEEEEE !important;
}

.RadioButton.ant-radio-button-wrapper-checked {
    border-radius: 15px !important;
    background-color: #F0FFF0;
    border: solid 2px #39B13D !important;
    color: #212121;
}

.RadioButton.ant-radio-button-wrapper-checked:hover {
    color: #212121;
}

.RadioButton:hover {
    color: #212121;
}

.RadioButton.ant-img {
    margin-right: 10px !important;
}

.ant-card-body {
    padding: 0;
}

.DisableButton:hover {
    cursor: not-allowed !important;
    color: #616161 !important;
}

.DisableButton {
    animation-duration: 0s !important;
}

.calendarButton {
    border: none;
    margin: 0 !important;
    height: 33px !important;
    width: 33px !important;
}