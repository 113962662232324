body {
    background: #f3f1f2;
    font-family: sans-serif;
  }
  
  /* .quillIDE {
    margin: 1rem 4rem;
  } */
  
  .quillIDE .ql-container {
    border-bottom-left-radius:2px;
    border-bottom-right-radius:2px;
    background: #ffffff;
  }
  
  /* Snow Theme */
  .quillIDE .ql-snow.ql-toolbar {
    display: block;
    background: #eaececf5;
    border-top-left-radius:2px;
    border-top-right-radius:2px;
  }
  
  /* Bubble Theme */
  .quillIDE .ql-bubble .ql-editor {
    border: 1px solid #ccc;
    border-radius:2px;
  }
  
  .quillIDE .ql-editor {
    min-height: 18em;
    /* width: max-content; */
  }
  
  .themeSwitcher {
    margin-top: 0.5em;
    font-size: small;
  }