body {
  background: #f3f1f2;
  font-family: sans-serif;
}

/* .quillSTATMENT {
    margin: 1rem 4rem;
  } */

.quillSTATMENT .ql-container {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background: #ffffff;
}

/* Snow Theme */
.quillSTATMENT .ql-snow.ql-toolbar {
  display: flex;
  background: #ffffff77;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  height: 34px;
  align-items: center;


}

/* Bubble Theme */
.quillSTATMENT .ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 2px;
}

.quillSTATMENT .ql-editor {
  min-height: 38px;
  /* width: max-content; */
}

.themeSwitcher {
  margin-top: 0.5em;
  font-size: small;
}