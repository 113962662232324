.container {
    position: relative;
    width: 50%;
  }
  
  .image {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #ffffff;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
    border-radius: 5px;
  }
  
  .container:hover .overlay {
    height: 100%;
  }
  
  .text {
    white-space: nowrap; 
    color: white;
    font-size: 20px;
    position: absolute;
    overflow: hidden;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
  }
  .ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
    margin-top: 0;
  }